import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageComponent } from './page/page.component';
import { EncuestaComponent } from './section/encuesta/encuesta.component';
import { ReaccionComponent } from './section/reaccion/reaccion.component';
import { ComentarioComponent } from './partials/comentario/comentario.component';
import { PreguntaComponent } from './partials/pregunta/pregunta.component';
import { UneteComponent } from './section/unete/unete.component';

@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    EncuestaComponent,
    ReaccionComponent,
    ComentarioComponent,
    PreguntaComponent,
    UneteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DeviceDetectorModule.forRoot(),
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
