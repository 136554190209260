import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { parse } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  private URL: string = environment.urlLambdaSms;

  constructor(private _http: HttpClient) { }

  /* Enviar */
  async send(numero) {
    // try
    try {
      // event
      let data = {
        "phoneNumber": parseInt(numero)
      }
      // se hace llamado a la lambda
      let resp: any = await this._http.post(this.URL, data).toPromise();
      // valido el resp
      if (!!resp.statusCode) {
        // code
        switch (resp.statusCode) {
          // success
          case "0":
            return {
              status: true,
              title: "¡Listo!",
              text: "Muy pronto nos vemos en tu cel ;)"
            };
            break;
          // failed
          case "20-05A":
            throw "El numero enviado no es valido."
            break;
          // failed
          case "20-09A":
            return {
              status: true,
              title: "",
              text: "Tranqui, ya tenemos tu cel registrado en nuestra mente y corazón."
            };
            break;
          // failed
          default:
            //20-07A ++
            throw "Ha ocurrido un error inesperado, inténtalo nuevamente."
            break;
        }
      } else {
        // failed
        throw "Ha ocurrido un error inesperado, inténtalo nuevamente."
      }
    } catch (error) {
      // error
      return {
        status: false,
        text: error
      };
    }
  }
}
