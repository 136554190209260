import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../environments/environment';

declare var dataLayer: any;

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styles: []
})
export class EncuestaComponent implements OnInit {

  /* PUNTOS - RECOLECTADOS */
  public puntosResultados: Number[] = [];
  /* LISTA CON PREGUNTAS */
  public listaPreguntas: any = [];
  /* CONTROL # PREGUNTA */
  private numPreguntaActual: number = 0;
  /* PREGUNTA ACTUAL - SE MUESTRA */
  public preguntaActual: any;
  /* CLASE PREGUNTA - ANIMATED */
  public clasePregunta: string = "fadeIn";
  /* CLASE EVENTS - PREGUNTA */
  public clasePreguntaEvent: String = "";
  /* VALIDACION */
  public validaRespuesta: boolean = false;
  public isMobile: boolean = false;
  /* RESULTADO */
  public resultado = {
    "titulo": "",
    "tituloDos": "",
    "subtitulo": "",
    "url": environment.urlShare,
    "imagen": "",
    "zip": "",
    "imagenInstagram": "",
    "claseFondo": "",
    "introduccion": "",
    "diceLaPlata": "",
    "tipGeneral": "",
    "tipUno": "",
    "tipDos": ""
  }
  /* INTERNAS */
  protected claseColorActual: string = "color5";
  protected porcentajeCompletado: number = 0;

  /* DATOS QUE SE ENVIAN */
  @Output() claseContenido = new EventEmitter();

  constructor(private deviceService: DeviceDetectorService) {
    // RESPUESTAS - PUNTOS DE LAS PREGUNTAS
    this.puntosResultados.push(null); // 1
    this.puntosResultados.push(null); // 2
    this.puntosResultados.push(null); // 3
    this.puntosResultados.push(null); // 4
    this.puntosResultados.push(null); // 5
    this.puntosResultados.push(null); // 6
    this.puntosResultados.push(null); // 7
    this.puntosResultados.push(null); // 8
    this.puntosResultados.push(null); // 9
    this.puntosResultados.push(null); // 10
    // LISTA - PREGUNTAS
    this.listaPreguntas.push({
      id: "pregunta#1",
      pregunta: "¿Cuando llegan las facturas a fin de mes, tú qué haces?",
      opcion_1: "Cierro los ojos, hago como si no existieran…y espero que todo esté bien.",
      opcion_2: "Caen como balde de agua fría, pero cuando toca, toca.",
      opcion_3: "Agarro papel y lápiz y empiezo a organizar los pagos.",
      puntos_1: 0,
      puntos_2: 1,
      puntos_3: 3
    }); // 1 
    this.listaPreguntas.push({
      id: "pregunta#2",
      pregunta: '¿Qué opinas de la frase "un hueco tapa otro hueco"?',
      opcion_1: "Dame la pala, yo empiezo a cavar.",
      opcion_2: "Eso es mentira.",
      opcion_3: "Solo si es una emergencia.",
      puntos_1: 0,
      puntos_2: 3,
      puntos_3: 1
    }); // 2
    this.listaPreguntas.push({
      id: "pregunta#3",
      pregunta: "¿Gastas más de lo que ganas?",
      opcion_1: "Em… siguiente pregunta.",
      opcion_2: "No, para nada.",
      opcion_3: "Me descuadro un tris, pero nada mortal.",
      puntos_1: 0,
      puntos_2: 3,
      puntos_3: 1
    }); // 3
    this.listaPreguntas.push({
      id: "pregunta#4",
      pregunta: "¿Qué es para ti un ‘tarjetazo’?",
      opcion_1: "Un atajo para darme gustos ocasionales.",
      opcion_2: "Es mi forma de hacer mercado.",
      opcion_3: "Es mi última opción, cuando no hay de otra.",
      puntos_1: 1,
      puntos_2: 0,
      puntos_3: 3
    }); // 4
    this.listaPreguntas.push({
      id: "pregunta#5",
      pregunta: "“Uno gasta como gana”.",
      opcion_1: "Claro, para eso trabajo, para gastar.",
      opcion_2: "Tod@s tenemos nuestros días de debilidad…",
      opcion_3: "No, yo gasto exactamente lo que decido que voy a gastar.",
      puntos_1: 0,
      puntos_2: 1,
      puntos_3: 3
    }); // 5
    this.listaPreguntas.push({
      id: "pregunta#6",
      pregunta: "Ahorrar es sinónimo de:",
      opcion_1: "Pagar menos de lo normal por alguna cosa.",
      opcion_2: "Renunciar a lo que me gusta.",
      opcion_3: "Conseguir lo que quiero.",
      puntos_1: 1,
      puntos_2: 0,
      puntos_3: 3
    }); // 6
    this.listaPreguntas.push({
      id: "pregunta#7",
      pregunta: "Tu cara ante la palabra presupuesto es:",
      opcion_1: "Como si me hablaran en chino.",
      opcion_2: "Felicidad, un buen presupuesto lo puede todo.",
      opcion_3: "De confusión, hace mil años no hago uno.",
      puntos_1: 0,
      puntos_2: 3,
      puntos_3: 1
    }); // 7
    this.listaPreguntas.push({
      id: "pregunta#8",
      pregunta: "Te cuentan de una inversión que te volverá millonario, tú piensas…",
      opcion_1: "Meto toda mi plata de una, no quiero ser millonario, sino billonario.",
      opcion_2: "De pronto invierto un poco, el que no arriesga un huevo…",
      opcion_3: "De eso tan bueno no dan tanto, primero investigo a fondo.",
      puntos_1: 0,
      puntos_2: 1,
      puntos_3: 3
    }); // 8
    this.listaPreguntas.push({
      id: "pregunta#9",
      pregunta: "¿Sabes exactamente cuánto ganas y gastas al mes?",
      opcion_1: "Tengo una tablita en Excel y todo.",
      opcion_2: "Me están pidiendo mucho.",
      opcion_3: "Tengo una idea, pero no cuento las monedas.",
      puntos_1: 3,
      puntos_2: 0,
      puntos_3: 1
    }); // 9
    this.listaPreguntas.push({
      id: "pregunta#10",
      pregunta: "¿Qué tan a menudo te preguntas: “¿en qué se me fue la plata?”",
      opcion_1: "Una o dos, cuando se me van las luces comprando.",
      opcion_2: "Tantas que ya perdí la cuenta.",
      opcion_3: "Casi nunca, sé bien qué hago con ella.",
      puntos_1: 1,
      puntos_2: 0,
      puntos_3: 3
    }); //10
    // INICIAR LA PRIMERA PREGUNTA
    this.siguientePregunta();
  }

  ngOnInit() {
    // se valida si es mobile
    if(this.deviceService.isMobile() || this.deviceService.isTablet()){
      // se cambia variable
      this.isMobile = true;
    }else{
      // se cambia variable
      this.isMobile = false;
    }
  }

  /* ACTUALIZA LA INFO DE LA PREGUNTA */
  siguientePregunta() {
    this.preguntaActual = this.listaPreguntas[this.numPreguntaActual];
  }

  /* RECIBE RESPUESTA - COMPONENTE */
  setRespuesta(data: any) {
    // Almaceno los puntos acumulados
    this.puntosResultados[this.numPreguntaActual] = data.puntos;
    // desaparece
    this.clasePregunta = "fadeOut";
    // desactiva opciones
    this.clasePreguntaEvent = "noEvents";
    // aumento porcentaje
    this.porcentajeCompletado += 10;
    // push datalayer
    dataLayer.push({
      'event': 'test',
      'percentageValue': this.porcentajeCompletado,
      'respuesta': data.texto
    });
    // valido si se cumplieron todas las preguntas
    if (this.numPreguntaActual < this.listaPreguntas.length - 1) {
      // aumento a la pregunta siguiente
      this.numPreguntaActual++;
      // Se espera 1 seg | Aparecer la otra pregunta
      setTimeout(() => {
        // paso la pregunta
        this.siguientePregunta();
        // aparece
        this.clasePregunta = "fadeIn";
        // activa opciones
        this.clasePreguntaEvent = "";
        // color de contenido
        this.claseContenido.emit(this.colorAleatorio());
      }, 1000);
    } else {
      // RESULTADO
      this.calcularResultado()
    }
  }

  /* RESULTADO */
  calcularResultado() {
    // reducer para sumar
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    // calculo total
    let totalPuntos = this.puntosResultados.reduce(reducer);
    // condicionales
    if (totalPuntos >= 0 && totalPuntos <= 5) {
      //
      this.resultado.titulo = "Surfista";
      this.resultado.tituloDos = "financiero";
      this.resultado.subtitulo = "Más tiempo bajo el mar que dominando las olas.";
      this.resultado.url = this.resultado.url + "/surfista";
      this.resultado.imagen = "imagen_surfista_financiero.png";
      this.resultado.zip = "Surfista_financiero.zip";
      this.resultado.imagenInstagram = "Surfista_Financiero_Instagram_Story.png";
      this.resultado.claseFondo = "resultadoR";
      this.resultado.introduccion = "Es difícil ser el rey de las olas si no te preparas para el surf. Sabemos que en el mar financiero nadie está libre de tormentas, pero ayuda mucho tener las técnicas y herramientas correctas.";
      this.resultado.diceLaPlata = "Juntos nos tiramos al agua sin pensarlo dos veces.";
      this.resultado.tipGeneral = "La marea puede cambiar en cualquier momento, pero si te organizas y tienes una ruta con tu plata antes de salir al mar, estarás más protegido de tiburones morosos, olas crediticias o naufragios de ahorro.";
      this.resultado.tipUno = "Piénsalo dos veces: haz un presupuesto antes de surfear con tu plata.";
      this.resultado.tipDos = "Organiza tu plata en Bolsillos: son como flotadores, uno para cada gasto.";

    } else if (totalPuntos >= 6 && totalPuntos <= 10) {
      //
      this.resultado.titulo = "Encantador@";
      this.resultado.tituloDos = "de culebras";
      this.resultado.subtitulo = "Tienes domadas las culebras, pero sigues bailando a su ritmo.";
      this.resultado.url = this.resultado.url + "/encantador";
      this.resultado.imagen = "imagen_encantador_culebras.png";
      this.resultado.zip = "Encantador_de_culebras.zip";
      this.resultado.imagenInstagram = "Encantador_de_culebras_Instagram_Story.png";
      this.resultado.claseFondo = "resultadoM";
      this.resultado.introduccion = "Todo sea por los estúpidos y sensualesº antojos que siempre aparecen en el momento preciso y saben convencerte para que digas: toma mi plata.";
      this.resultado.diceLaPlata = "Juntos nos hemos encariñado con las culebras.";
      this.resultado.tipGeneral = "Usa el método Bola de Nieve para pagar tus deudas de menor a mayor, así: paga la deuda más chiqui y después la siguiente pero sumando la plata que antes iba para la chiqui y así sucesivamente hasta salir de culebras.";
      this.resultado.tipUno = "Da el primer paso: no importa la cantidad, importa empezar.";
      this.resultado.tipDos = "Crea una Meta de ahorro: y dirígela hacia tus deudas para motivarte.";
    } else if (totalPuntos >= 11 && totalPuntos <= 15) {
      //
      this.resultado.titulo = "Acróbata";
      this.resultado.tituloDos = "elevad@";
      this.resultado.subtitulo = "No sabes cómo, pero haces malabares con la plata.";
      this.resultado.url = this.resultado.url + "/acrobata";
      this.resultado.imagen = "imagen_acrobata_elevado.png";
      this.resultado.zip = "Acrobata_elevado.zip";
      this.resultado.imagenInstagram = "Acrobata_elevado_Instagram_Story.png";
      this.resultado.claseFondo = "resultadoM";
      this.resultado.introduccion = "Pagos, antojos, imprevistos… pensar en todo eso da un poquito de vértigo, pero igual toca tirarse y lograr la voltereta. ¿Nos crees si te decimos que puede ser más fácil?";
      this.resultado.diceLaPlata = "Juntos vencemos el miedo a las alturas financieras.";
      this.resultado.tipGeneral = "Separa bien tu plata para pagar primero los gastos fijos (lo que sí o sí hay que pagar) y busca todo el ahorro posible en los gastos variables (mercado, transporte...) para poder darte un gusto a lo bien.";
      this.resultado.tipUno = "Piérdele el miedo al ahorro: míralo como una inversión en ti mism@.";
      this.resultado.tipDos = "Esconde una plata de ti mism@: pon a un amigo a cuidarla para imprevistos.";
    } else if (totalPuntos >= 16 && totalPuntos <= 20) {
      //
      this.resultado.titulo = "Influencer";
      this.resultado.tituloDos = "de la vida";
      this.resultado.subtitulo = "Momentos que cuenten, sin importar lo que cuesten.";
      this.resultado.url = this.resultado.url + "/influencer";
      this.resultado.imagen = "imagen_influencer_vida.png";
      this.resultado.zip = "Influencer_de_la_vida.zip";
      this.resultado.imagenInstagram = "Influencer_de_la_vida_Instragam_Story.png";
      this.resultado.claseFondo = "resultadoA";
      this.resultado.introduccion = "Parece que tienes tus gastos bajo control sin necesidad de contar el número de empanadas que compras al día. Vas a lo Hakuna Matata y te funciona... por ahora.";
      this.resultado.diceLaPlata = "Juntos coleccionamos los mejores recuerdos.";
      this.resultado.tipGeneral = "Si registramos cada momento en fotos… ¿por qué no registrar cada gasto en un presupuesto? Si conoces bien cómo estás usando tu plata vas a descubrir nuevos destinos para llevarla: ciudad ahorro o pueblo inversión.",
        this.resultado.tipUno = "Sigue el viaje de tu plata: antes, durante y después del gasto.";
      this.resultado.tipDos = "Crea un Bolsillo para cada gasto: así no se te embolata ni un peso.";
    } else if (totalPuntos >= 21 && totalPuntos <= 25) {
      //
      this.resultado.titulo = "Arquer@";
      this.resultado.tituloDos = "monetari@";
      this.resultado.subtitulo = "La mano no te tiembla para atajar gastos.";
      this.resultado.url = this.resultado.url + "/arquero";
      this.resultado.imagen = "imagen_arquero_monetario.png";
      this.resultado.zip = "Arquero_monetario.zip";
      this.resultado.imagenInstagram = "Arquero_monetari_Instagram_Story.png";
      this.resultado.claseFondo = "resultadoA";
      this.resultado.introduccion = "Tratas a la plata con guantes, pero de arquero. Ni un solo peso se te pasa, o sea que te fijas en los detalles, y ya sabes lo que dicen... esos son los que enamoran.";
      this.resultado.diceLaPlata = "Juntos la sacamos del estadio.";
      this.resultado.tipGeneral = "¿Qué tal jugar a la ofensiva de vez en cuando? Sal del arco y maneja tu plata por toda la cancha. Sácate a esos antojos innecesarios para llegar a la meta y marcar el golazo comprando lo que hace rato quieres y necesita más ahorro.";
      this.resultado.tipUno = "No te metas autogol: protege tu plata de tentaciones momentáneas.";
      this.resultado.tipDos = "Calienta antes del juego: haz un presupuesto de lo que vas a gastar.";
    } else if (totalPuntos >= 26 && totalPuntos <= 30) {
      //
      this.resultado.titulo = "Astronauta";
      this.resultado.tituloDos = "terrestre";
      this.resultado.subtitulo = "Con los pies en la tierra y la cabeza en tus metas.";
      this.resultado.url = this.resultado.url + "/astronauta";
      this.resultado.imagen = "imagen_astronauta_terrestre.png";
      this.resultado.zip = "Astronauta_terrestre.zip";
      this.resultado.imagenInstagram = "Astronauta_terrestre_Instagram_Story.png";
      this.resultado.claseFondo = "resultadoR";
      this.resultado.introduccion = "En tu relación con la plata, tienes los pies muy bien puestos. Sabes lo que gastas y no te da miedo hacerle frente en un presupuesto para cumplir tus metas.";
      this.resultado.diceLaPlata = "Juntos conquistaremos nuevos mundos.";
      this.resultado.tipGeneral = "Ya dominas el terreno del presupuesto… ¿qué tal despegar a las inversiones o al emprendimiento? Intenta llevar tus gastos e ingresos a un equilibrio donde ahorres y uses lo guardado para ganar algo extra en nuevos mundos.";
      this.resultado.tipUno = "Contagia a los demás: comparte tips de plata con tus amigos.";
      this.resultado.tipDos = "Crea Metas de ahorro para tu propio negocio o invierte en el de otros.";
    }
    // establesco | aparece div
    this.validaRespuesta = true;
    // envio clase | fondo
    this.claseContenido.emit(this.resultado.claseFondo);
    // push datalayer
    dataLayer.push ({
      'event': 'pageview-virtual',
      'pagePath': '/resultado',
      'pageTitle': this.resultado.titulo
    });
  }

  /* GENERA CLASE */
  colorAleatorio() {
    // lista de colores
    let colores = ["color1", "color3", "color4", "color5"];
    // elimino el actual
    colores.splice(colores.indexOf(this.claseColorActual), 1);
    // establezco la actual
    this.claseColorActual = colores[Math.floor(Math.random() * (colores.length))];
    // retorno la actual
    return this.claseColorActual;
  }

}
