import { Component, OnInit, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SmsService } from 'src/app/services/sms.service';

@Component({
  selector: 'app-unete',
  templateUrl: './unete.component.html',
  styles: []
})
export class UneteComponent implements OnInit {

  public disabledButton: boolean = false;
  public isMobile: boolean = false;
  /* numero form */
  public numero: number;
  /* valida */
  public validaResultado: boolean = false;
  /* texto */
  public textoResultado: string = "";
  public tituloResultado: string = "";
  public textoError: string = "";
  /* DATOS QUE LLEGAN */
  @Input() idBoton: any;

  constructor(private deviceService: DeviceDetectorService, private _smsService: SmsService) { }

  ngOnInit() {
    // se valida si es mobile
    if(this.deviceService.isMobile() || this.deviceService.isTablet()){
      // se cambia variable
      this.isMobile = true;
    }else{
      // se cambia variable
      this.isMobile = false;
    }
  }

  /* ENVIAR SMS */
  async sendSms(){
    // valido el numero
    if(!!this.numero){
      // envio de sms
      let resp: any = await this._smsService.send(this.numero);
      // valido resultado
      if(resp.status){
        // cond
        this.validaResultado = true;
        // texto
        this.tituloResultado = resp.title;
        this.textoResultado = resp.text;
        // limpiar variables
        this.numero = null;
        this.textoError = "";
      }else{
        // texto
        this.textoError = resp.text;
      }
    }
  }

}
