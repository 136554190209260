import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReaccionService {

  private URL: string = environment.urlLambda;

  constructor(private _http: HttpClient) {}

  /* OBTENER DATA */
  async getParams() {
    // try
    try {
      // se hace llamado a la lambda
      let resp: any = await this._http.post(this.URL, '').toPromise();
      // valido que existan datos | cuando sea true
      if(!!resp.data.viewInformation && !resp.data.countReactions && !resp.data.descriptionReactions){
        // si no existen se deja el false
        resp.data.viewInformation = false;
      }
      // si existen | se retorna data y lista
      return {
        "data": {
          "mostrar": resp.data.viewInformation,
          "cantidad": resp.data.countReactions,
          "texto": resp.data.descriptionReactions
        },
        "lista": this.generarListaComentarios(resp.data.listComments)
      }
    } catch (error) {
      // si hay error | retorna vacio
      return {
        "data": {
          "mostrar": false,
          "cantidad": 0,
          "texto": ""
        },
        "lista": []
      }
    }
  }

  /* TRATAR LISTA */
  private generarListaComentarios(listaComentarios: any) {
    // se crea variable 
    let lista = [];
    // valido que exista la lista
    if (!!listaComentarios) {
      // recorro la lista
      for (let i = 0; i < listaComentarios.length; i++) {
        // elemento
        const element = listaComentarios[i];
        // se agrega a la lista
        lista.push({
          usuario: element.user,
          comentario: element.text,
          height: this.heightAleatorio(),
          claseColor: this.colorAleatorio()
        });
      }
    }
    // retorno lista
    return lista;
  }

  /* GENERAR PARAMETROS */
  heightAleatorio() {
    return Math.round(Math.random() * (250 - 100) + 100);
  }
  colorAleatorio() {
    let colores = ["color1", "color2", "color3", "color4", "color5"]
    return colores[Math.floor(Math.random() * (colores.length))]
  }
}
