import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-comentario',
  templateUrl: './comentario.component.html',
  styles: []
})
export class ComentarioComponent implements OnInit {

  /* DATOS QUE LLEGAN */
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

}
