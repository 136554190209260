import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ReaccionService } from 'src/app/services/reaccion.service';

@Component({
  selector: 'app-reaccion',
  templateUrl: './reaccion.component.html',
  styles: []
})
export class ReaccionComponent implements OnInit {

  /* LISTA DE COMENTARIOS */
  public listaComentarios: any = [];
  public totalComentarios: number = 0;
  /* CONTADOR */
  public contenidoContador: any = {
    "mostrar": false
  };
  /* CONDICIONALES - CLASES */
  public claseColumnaComentarios: string = "ocultar";

  /* DATOS QUE SE ENVIAN */
  @Output() abrirModal = new EventEmitter();

  constructor(private _reaccionService: ReaccionService) { }

  ngOnInit() {
    // se obtiene los parametros
    this.obtenerParametros();
  }

  // OBTENER - SERVICE
  async obtenerParametros() {
    // Se consulta al service
    let parametros: any = await this._reaccionService.getParams();
    // se obtiene la informacion del contador
    this.contenidoContador = parametros.data;
    // se obtiene la lista de comentarios
    this.listaComentarios = parametros.lista;
    // se calcula el total
    this.totalComentarios = parametros.lista.length;
  }

  /* FILTRA LOS COMENTARIOS EN 3 - PARA LAS COLUMNAS */
  filtrarComentariosLeft() {
    let maximo = this.totalComentarios / 3;
    return this.listaComentarios.filter((item, index) => index < maximo);
  }
  filtrarComentariosCenter() {
    let cantidadColumna = this.totalComentarios / 3;
    let minimo = cantidadColumna;
    let maximo = this.totalComentarios - cantidadColumna;
    return this.listaComentarios.filter((item, index) => index >= minimo && index < maximo);
  }
  filtrarComentariosRight() {
    let cantidadColumna = this.totalComentarios / 3;
    let minimo = this.totalComentarios - cantidadColumna;
    return this.listaComentarios.filter((item, index) => index >= minimo);
  }

  /* CARGAS COMENTARIOS */
  cargarMasComentariosMobile() {
    // se cambia la clase
    this.claseColumnaComentarios = "";
  }

  /* ENVIAR EVENTO - MODAL */
  emitAbrirModalEncuesta(){
    this.abrirModal.emit(true);
  }

}
