import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

declare var AOS: any;
declare var dataLayer: any;

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styles: []
})
export class PageComponent implements OnInit {

  /* CONTROL - MODAL */
  protected controlModalVideo: boolean = false;
  public claseModalVideo = "hidden";
  protected controlModalEncuesta: boolean = false;
  public claseModalEncuesta = "hidden";
  protected controlModalUnete: boolean = false;
  public claseModalUnete = "hidden";
  /* CLASES */
  public claseColorEncuesta: string = "color5";
  /* TEXTO - INFORMACION */
  public textoLeer: string = "Creemos que hay otra forma de hacer las cosas, otra forma de manejar la plata. No es la que repetimos en refranes, no es la de los bancos, ni la que te digamos nosotros... la única forma de manejar la plata ¡es la tuya! Y existimos para ayudarte a hacerla posible, para que mejores tu relación con la plata. Así que ahorra, organiza, guarda y hasta gasta… ¡pero en lo que realmente quieres! Sabemos que entre obligación y tentación no es fácil… pero estamos contigo todos los días, justo en tu cel.";
  public textoLeido: string = "";
  /* validar */
  public validaBotonVerMas: boolean = false;
  public validaMasTexto: boolean = false;
  /* valida mobile */
  public isMobile: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document, private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    // Iniciar AOS
    AOS.init();
    // si es mobile se empieza el cargue de texto de informacion
    if(this.deviceService.isMobile() || this.deviceService.isTablet()){
      // fn que inicia resaltado de texto
      this.iniciarLeerTexto();
      // cambio variable
      this.isMobile = true;
    }
  }

  /* MODALES - CONTROLES */
  cerrarModalVideo() {
    // Agrego clase a section
    this.claseModalVideo = "fadeOut";
    // se espera 0,7 seg para ocultar del todo
    setTimeout(() => {
      this.claseModalVideo = "hidden";
    }, 700);
    // Quito clase a body
    this.removeClaseBody();
    // push
    dataLayer.push ({
      'event': 'close-modal',
      'pagePath': '/modal-video',
      'pageTitle': 'Ver video'
    });
  }
  abrirModalVideo() {
    // cambio control
    this.controlModalVideo = true;
    // Agrego clase a section
    this.claseModalVideo = "fadeIn";
    // Agrego clase a body
    this.addClaseBody();
    // push
    dataLayer.push ({
      'event': 'pageview-virtual',
      'pagePath': '/modal-video',
      'pageTitle': 'Ver video'
    });
  }
  cerrarModalEncuesta() {
    // Agrego clase a section
    this.claseModalEncuesta = "fadeOut";
    // se espera 0,7 seg para ocultar del todo
    setTimeout(() => {
      this.claseModalEncuesta = "hidden";
    }, 700);
    // Quito clase a body
    this.removeClaseBody();
    // push
    dataLayer.push ({
      'event': 'close-modal',
      'pagePath': '/modal-encuesta',
      'pageTitle': 'Realizar Encuesta'
    });
  }
  abrirModalEncuesta() {
    // cambio control
    this.controlModalEncuesta = true;
    // Agrego clase a section
    this.claseModalEncuesta = "fadeIn";
    // Agrego clase a body
    this.addClaseBody();
    // push
    dataLayer.push ({
      'event': 'pageview-virtual',
      'pagePath': '/modal-encuesta',
      'pageTitle': 'Realizar Encuesta'
    });
  }
  cerrarModalUnete() {
    // Agrego clase a section
    this.claseModalUnete = "fadeOut";
    // se espera 0,7 seg para ocultar del todo
    setTimeout(() => {
      this.claseModalUnete = "hidden";
    }, 700);
    // Quito clase a body
    this.removeClaseBody();
    // push
    dataLayer.push ({
      'event': 'close-modal',
      'pagePath': '/modal-unete',
      'pageTitle': 'Unete'
    });
  }
  abrirModalUnete() {
    // cambio control
    this.controlModalUnete = true;
    // Agrego clase a section
    this.claseModalUnete = "fadeIn";
    // Agrego clase a body
    this.addClaseBody();
    // push
    dataLayer.push ({
      'event': 'pageview-virtual',
      'pagePath': '/modal-unete',
      'pageTitle': 'Unete'
    });
  }

  /* BOTONES - CONTROLES */
  mostrarMasTexto() {
    // habilito div
    this.validaMasTexto = true;
    // oculto boton
    this.validaBotonVerMas = false;
  }

  /* CLASE - BODY */
  addClaseBody() {
    // agregar clase a body - para desactivar el scroll
    this.document.body.classList.add('noScroll');
  }
  removeClaseBody() {
    // agregar clase a body - para activar el scroll
    this.document.body.classList.remove('noScroll');
  }

  /* RECIBIR CLASE - ENCUESTA */
  setclaseColorEncuesta(event) {
    // se establece clase - color de fondo
    this.claseColorEncuesta = event;
  }

  /* RECIBIR EVENTO - ABRIR MODAL ECUESTA */
  setModalEncuesta(event) {
    // se valida
    if(event){
      // se abre modal
      this.abrirModalEncuesta();
    }
  }

  /* DETECTAR LA TECLA ESC | CERRAR MODAL */
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if (this.controlModalVideo) this.cerrarModalVideo();
      if (this.controlModalEncuesta) this.cerrarModalEncuesta();
      if (this.controlModalUnete) this.cerrarModalUnete();
    }
  }

  /* APARECER TEXTO */
  async iniciarLeerTexto() {
    if (this.textoLeido == "") {
      let texto = this.textoLeer;
      let letras = texto.split(" ");
      for (let letra of letras) {
        this.textoLeido += await this.returnLetra(letra) + " ";
      }
      // habilito el boton ver mas
      this.validaBotonVerMas = true;
    }
  }
  returnLetra(x) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(x);
      }, 200);
    });
  }

}
