import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pregunta',
  templateUrl: './pregunta.component.html',
  styles: []
})
export class PreguntaComponent implements OnInit {

  /* DATOS QUE LLEGAN */
  @Input() data: any;
  @Input() claseDiv: any;

  /* DATOS QUE SE ENVIAN */
  @Output() respuesta = new EventEmitter();
  
  constructor() {
  }

  ngOnInit() {
  }

  /* OPCIONES DE RESPUESTA */
  opcionUno(){
    // envio
    this.emit({
      "texto": this.data.opcion_1,
      "puntos": this.data.puntos_1
    });
  }
  opcionDos(){
    // envio
    this.emit({
      "texto": this.data.opcion_2,
      "puntos": this.data.puntos_2
    });
  }
  opcionTres(){
    // envio
    this.emit({
      "texto": this.data.opcion_3,
      "puntos": this.data.puntos_3
    });
  }

  /* DEVOLVER RESPUESTA */
  emit(dataEnviar: any){
    this.respuesta.emit(dataEnviar);
  }

}
